import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import "./ItemView.css";
import { monday } from "../helpers/mondaySdk";
import "monday-ui-react-core/dist/main.css";
import {
  Dropdown,
  ButtonGroup,
  Flex,
  Box,
  Button,
  Icon,
  Text,
  Skeleton,
} from "monday-ui-react-core";
import {
  getBoardSettings,
  getExistingTemplates,
  getGlobalSettings,
  updateBoardSettings,
} from "./TemplateModal";
import { Update, Settings } from "monday-ui-react-core/icons";
import * as amplitude from "@amplitude/analytics-browser";
import logo from "../images/approvals-logo.png";
import TextareaAutosize from "react-textarea-autosize";
import { groupBy } from "lodash";
import { formatRelative, parseISO } from "date-fns";
import axios from "axios";
import { Heading } from "monday-ui-react-core/next";
import { motion, AnimatePresence } from "framer-motion";

export const getItemStorageKey = (itemId) => `item-${itemId}-approvers`;
const getExistingApprovers = async (itemId) => {
  const key = getItemStorageKey(itemId);
  const existingApprovers = await monday.storage.getItem(key);
  return JSON.parse(existingApprovers.data.value || "[]");
};

const getItemCommentStorageKey = (itemId) => `item-${itemId}-comments`;
const getExistingComments = async (itemId) => {
  const key = getItemCommentStorageKey(itemId);
  const existingComments = await monday.storage.getItem(key);
  return JSON.parse(existingComments.data.value || "[]");
};
const setCommentInStorage = async (itemId, comment) => {
  const key = getItemCommentStorageKey(itemId);
  const existingComments = await monday.storage.getItem(key);
  const comments = JSON.parse(existingComments.data.value || "[]");
  comments.push(comment);
  await monday.storage.setItem(key, JSON.stringify(comments));
};

const statusTexts = {
  approved: "✅ Approved",
  "changes-requested": "🔁 Changes requested",
  rejected: "❌ Rejected",
};

const ItemView = () => {
  const [context, setContext] = useState();
  const [parentBoardId, setParentBoardId] = useState();
  const [settings, setSettings] = useState();
  const [users, setUsers] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [previewApprovers, setPreviewApprovers] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const [item, setItem] = useState();
  const [newUserDropdownKey, setNewUserDropdownKey] = useState(0);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(true);

  const appMajorVersion = context?.appVersion?.versionData?.major;
  const hasComments = appMajorVersion >= 2;
  const commentsByUserId = groupBy(comments, "userId");

  useEffect(() => {
    amplitude.track("Item viewed");
  }, []);

  useEffect(() => {
    monday.execute("valueCreatedForUser");

    monday.get("sessionToken").then((res) => {
      setToken(res.data);
    });

    monday.listen("context", (res) => {
      setContext(res.data);

      if (res.data?.user?.isViewOnly) {
        return;
      }

      // Get users
      monday
        .api(
          `query {
           users {
             id
             name
             photo_thumb_small
           }
         }`
        )
        .then((res) => {
          setUsers(res.data.users);
        });

      // get current user
      monday
        .api(
          `query {
           me {
             id
             name
             photo_thumb_small
           }
         }`
        )
        .then((res) => {
          if (res?.data?.me) {
            setCurrentUser(res.data.me);
          }
        });
    });

    monday.listen("settings", (res) => {
      setSettings(res.data);
    });
  }, []);

  useEffect(() => {
    // verify configured column exists
    if (settings?.approvalStatusColumn) {
      const columnId = Object.keys(settings?.approvalStatusColumn)[0];
      monday
        .api(
          `query {
            boards (ids: ${context.boardId}) {
              columns (ids: ["${columnId}"]) {
                id
                title
              }
            }
          }`
        )
        .then((res) => {
          // unset column config so it prompts the user to reconfigure
          // not removing it from the stored settings just incase this particular users API response is incorrect
          if (!res.data.boards[0].columns.length) {
            setSettings((settings) => {
              return {
                ...settings,
                approvalStatusColumn: null,
              };
            });
          }
        });
    }
  }, [context?.boardId, settings?.approvalStatusColumn]);

  const addItemUpdate = useCallback(
    ({ text, status }) => {
      const body = `${text}

        ${statusTexts[status]} with Approval Workflows`;

      monday
        .api(
          `mutation {
          create_update (item_id: ${context.itemId}, body: "${body}") {
            id
          }
        }`
        )
        .then((res) => {
          const newComment = {
            userId: currentUser.id,
            updateId: res.data.create_update.id,
            text,
            status,
            date: new Date().toISOString(),
          };
          setCommentInStorage(context.itemId, newComment);

          setComments([...comments, newComment]);
        });

      amplitude.track("Comment added", {
        status,
      });
    },
    [context?.itemId, currentUser?.id, comments]
  );

  const refreshItem = useCallback(async () => {
    if (!context?.itemId) return;

    if (context?.user?.isViewOnly) {
      return;
    }

    monday
      .api(
        `query {
            items(ids: ${context.itemId}) {
              id
              name
              group {
                id
              }
              parent_item {
                board {
                  id
                }
              }
              column_values {
                id
                value
                type
              }
            }
          }`
      )
      .then(async (res) => {
        const itemDetails = res.data.items[0];
        setItem(itemDetails);

        let boardIdForTemplates = context.boardId;
        if (itemDetails?.parent_item?.board?.id) {
          boardIdForTemplates = itemDetails.parent_item.board.id;
        }
        setParentBoardId(boardIdForTemplates);

        const [existingApprovers, boardSettings, newComments, globalSettings] =
          await Promise.all([
            getExistingApprovers(context.itemId),
            getBoardSettings(boardIdForTemplates),
            getExistingComments(context.itemId),
            getGlobalSettings(),
          ]);

        const templates = await getExistingTemplates();
        setTemplates(templates);

        if (
          existingApprovers.length === 0 &&
          boardSettings?.defaultTemplateForGroup &&
          boardSettings.defaultTemplateForGroup[itemDetails?.group?.id]
        ) {
          const defaultApprovers =
            templates[
              boardSettings.defaultTemplateForGroup[itemDetails?.group?.id]
            ]?.approvers || [];

          await monday.storage.setItem(
            getItemStorageKey(context.itemId),
            JSON.stringify(defaultApprovers)
          );
          setApprovers(defaultApprovers);
        } else if (
          existingApprovers.length === 0 &&
          boardSettings?.defaultTemplateId
        ) {
          const defaultApprovers =
            templates[boardSettings.defaultTemplateId]?.approvers || [];

          await monday.storage.setItem(
            getItemStorageKey(context.itemId),
            JSON.stringify(defaultApprovers)
          );
          setApprovers(defaultApprovers);
        } else if (
          existingApprovers.length === 0 &&
          globalSettings?.newBoardDefaultTemplateId
        ) {
          const defaultApprovers =
            templates[globalSettings.newBoardDefaultTemplateId]?.approvers ||
            [];

          await monday.storage.setItem(
            getItemStorageKey(context.itemId),
            JSON.stringify(defaultApprovers)
          );
          setApprovers(defaultApprovers);

          updateBoardSettings(boardIdForTemplates, {
            defaultTemplateId: globalSettings.newBoardDefaultTemplateId,
          });
        } else {
          setApprovers(existingApprovers);
        }

        setComments(newComments);
        setLoading(false);
      });
  }, [
    context?.itemId,
    context?.boardId,
    context?.user?.isViewOnly,
    setParentBoardId,
  ]);

  useEffect(() => {
    if (context && !context?.user?.isViewOnly) {
      refreshItem();
    }
  }, [refreshItem, context]);

  const updateApprovalStatusColumn = (updatedApprovers) => {
    const columnId =
      settings?.approvalStatusColumn &&
      Object.keys(settings.approvalStatusColumn)[0];
    const itemId = context.itemId;
    const boardId = context.boardId;

    let status = "⏳ Pending";
    let statusKey;

    if (updatedApprovers.every((approver) => approver?.status === "approved")) {
      status = statusTexts["approved"];
      statusKey = "approved";
    }

    if (
      updatedApprovers.some(
        (approver) => approver?.status === "changes-requested"
      )
    ) {
      status = statusTexts["changes-requested"];
      statusKey = "changes-requested";
    }

    if (updatedApprovers.some((approver) => approver?.status === "rejected")) {
      status = statusTexts["rejected"];
      statusKey = "rejected";
    }

    if (columnId) {
      monday
        .api(
          `mutation {
            change_simple_column_value (board_id: ${boardId}, item_id: ${itemId}, column_id: "${columnId}", value: "${status}") {
              id
            }
          }`
        )
        .then((res) => {
          // do something
          getExistingApprovers(context.itemId).then((approvers) => {
            setApprovers(approvers);
          });
        });
    }

    const itemAssignees = item?.column_values.find(
      (column) => column.id === "person" && column.type === "people"
    )?.value;

    if (itemAssignees && statusKey) {
      JSON.parse(itemAssignees).personsAndTeams.forEach(({ id }) => {
        monday.api(
          `
            mutation {
              create_notification (text: "Approval status updated to ${status}", target_type: Project, target_id: ${itemId}, user_id: ${id}) {
                text
              }
            }
          `
        );
      });
    }

    if (statusKey && appMajorVersion >= 3) {
      axios.post(
        "https://approvals.change1t.com/monday/approval-status-changed",
        {
          itemId,
          boardId,
          statusKey,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      );
    }
  };

  const openTemplatePicker = () => {
    monday
      .execute("openAppFeatureModal", {
        urlPath: "/template",
        width: 800,
        height: 600,
        urlParams: {
          parentBoardId,
          itemId: context.itemId,
        },
      })
      .then((res) => {
        refreshItem();
      });
  };

  const optionsAvatar = users.map((user) => {
    return {
      value: user.id,
      label: user.name,
      leftAvatar: user.photo_thumb_small,
    };
  });

  const optionsWithoutApprovers = optionsAvatar.filter(
    (option) =>
      !approvers.some((approver) => `${approver?.userId}` === option.value)
  );

  const onSelectApprover = async (event, index) => {
    const userId = event?.value;
    if (!userId) return;

    const previousUserId = approvers[index]?.userId;
    // TODO should we handle conflicts, refresh the approvers list?
    const existingApprovers = await getExistingApprovers(context.itemId);

    const newApprovers = [...existingApprovers];

    if (previousUserId) {
      const previousApproverIndex = existingApprovers.findIndex(
        (itemApprover) => itemApprover.userId === previousUserId
      );
      if (previousApproverIndex !== -1) {
        newApprovers[previousApproverIndex] = {
          userId,
        };
      }
    } else {
      newApprovers.push({ userId });
      setNewUserDropdownKey((key) => key + 1);
    }

    await monday.storage.setItem(
      getItemStorageKey(context.itemId),
      JSON.stringify(newApprovers)
    );

    setApprovers(newApprovers);

    updateApprovalStatusColumn(newApprovers);

    amplitude.track(previousUserId ? "Approver changed" : "Approver added");
  };

  const onRemoveApprover = async ({ userId }) => {
    if (!userId) return;

    const itemApprovers = await getExistingApprovers(context.itemId);
    const approverIndex = itemApprovers.findIndex(
      (itemApprover) => itemApprover.userId === userId
    );

    itemApprovers.splice(approverIndex, 1);

    await monday.storage.setItem(
      getItemStorageKey(context.itemId),
      JSON.stringify(itemApprovers)
    );

    setApprovers(itemApprovers);

    updateApprovalStatusColumn(itemApprovers);

    amplitude.track("Approver removed");
  };

  const onChangeStatus = async (approver, status) => {
    const itemApprovers = await getExistingApprovers(context.itemId);
    const approverIndex = itemApprovers.findIndex(
      (itemApprover) => itemApprover.userId === approver.userId
    );

    itemApprovers[approverIndex].status = status;

    await monday.storage.setItem(
      getItemStorageKey(context.itemId),
      JSON.stringify(itemApprovers)
    );

    setApprovers(itemApprovers);

    updateApprovalStatusColumn(itemApprovers);

    amplitude.track("Status changed", {
      status,
    });

    const message = {
      approved: "Approved",
      "changes-requested": "Changes requested",
      rejected: "Rejected",
    }[status];

    monday.execute("notice", {
      message,
      type: "success", // or "error" (red), or "info" (blue)
      timeout: 4000,
    });
  };

  const applyTemplateToApprovers = (templateId) => {
    const newApprovers = [
      ...approvers,
      ...templates[templateId].approvers,
    ].filter(
      (approver, index, self) =>
        index ===
        // userId is storage as both string and number :(
        // eslint-disable-next-line eqeqeq
        self.findIndex((t) => t.userId == approver.userId)
    );
    return newApprovers;
  };

  if (context?.user?.isViewOnly) {
    return <ViewerOnly />;
  }

  // Stops default value from being populated too early with undefined
  if (!users.length) return null;

  return (
    <Box>
      {!settings?.approvalStatusColumn && (
        <Box paddingX={Box.paddings.MEDIUM}>{welcomeMessage()}</Box>
      )}
      <Box padding={Box.paddings.MEDIUM}>
        <Box
          paddingX={Box.paddings.SMALL}
          marginBottom={Box.marginBottoms.SMALL}
        >
          {loading && <Skeleton width={"100%"} height={40}></Skeleton>}
          {!loading && (
            <Flex
              gap={8}
              align={Flex.align.CENTER}
              direction={Flex.directions.ROW}
              wrap={true}
            >
              <Text>Apply templates:</Text>
              {Object.entries(templates).map(([templateId, template]) => (
                <div
                  key={templateId}
                  onMouseEnter={() => {
                    const newApprovers = applyTemplateToApprovers(templateId);
                    const difference = newApprovers
                      .filter(
                        (approver) =>
                          !approvers.some((a) => a.userId === approver.userId)
                      )
                      .map((approver) => ({
                        ...approver,
                        isPreview: true,
                      }));
                    setPreviewApprovers(difference);
                    amplitude.track("Template previewed", {
                      templateId,
                    });
                  }}
                  onMouseOut={() => {
                    setPreviewApprovers([]);
                  }}
                >
                  <Button
                    size={Button.sizes.SMALL}
                    kind={Button.kinds.SECONDARY}
                    onClick={() => {
                      const newApprovers = applyTemplateToApprovers(templateId);

                      monday.storage.setItem(
                        getItemStorageKey(context.itemId),
                        JSON.stringify(newApprovers)
                      );
                      setPreviewApprovers([]);
                      setApprovers(newApprovers);
                      updateApprovalStatusColumn(newApprovers);

                      amplitude.track("Quick template applied", {
                        templateId,
                      });
                    }}
                  >
                    {template.name}
                  </Button>
                </div>
              ))}
              <Button
                size={Button.sizes.SMALL}
                leftIcon={Settings}
                onClick={openTemplatePicker}
              >
                Configure templates
              </Button>
            </Flex>
          )}
        </Box>
        <Box paddingX={Box.paddings.SMALL}>
          <Heading type={Heading.types.H3} weight={Heading.weights.BOLD}>
            Approvers
          </Heading>
        </Box>
        <Flex
          gap={6}
          direction={Flex.directions.COLUMN}
          align={Flex.align.STRETCH}
        >
          <AnimatePresence>
            {!loading &&
              [...approvers, ...previewApprovers, null].map(
                (approver, index) => (
                  // Force changing keys so the add new user dropdown remounts
                  <motion.div
                    key={
                      approver?.isPreview
                        ? `${approver?.userId}-preview`
                        : approver?.userId ?? newUserDropdownKey
                    }
                    initial={{
                      opacity: approver?.isPreview ? 0 : 1,
                    }}
                    animate={{
                      opacity: approver?.isPreview ? 0.5 : 1,
                    }}
                    transition={{
                      type: "tween",
                      duration: 0.5,
                    }}
                  >
                    <Box
                      backgroundColor={
                        Box.backgroundColors.ALL_GREY_BACKGROUND_COLOR
                      }
                      rounded={Box.roundeds.MEDIUM}
                      padding={Box.paddings.SMALL}
                    >
                      <Flex
                        gap={8}
                        direction={Flex.directions.COLUMN}
                        alignItems={Flex.align.STRETCH}
                        align={Flex.align.STRETCH}
                      >
                        <Flex
                          key={approver?.userId ?? newUserDropdownKey}
                          gap={8}
                          align={Flex.align.STRETCH}
                        >
                          <div style={{ flex: "1 1 auto" }}>
                            <Dropdown
                              insideOverflowContainer
                              placeholder="Add new approver"
                              options={optionsWithoutApprovers}
                              value={optionsAvatar.find(
                                // Converting to string because the API has changed to always using strings for IDs. Storage has ints stored for old values
                                (option) =>
                                  option.value === `${approver?.userId}`
                              )}
                              onChange={(event) =>
                                event
                                  ? onSelectApprover(event, index)
                                  : onRemoveApprover({
                                      userId: approver.userId,
                                    })
                              }
                            ></Dropdown>
                          </div>
                          <Flex style={{ flex: "0 0 auto" }}>
                            <ButtonGroup
                              groupAriaLabel="button group aria label"
                              value={approver?.status}
                              size={ButtonGroup.sizes.MEDIUM}
                              // Converting to string because the API has changed to always using strings for IDs. Storage has ints stored for old values
                              disabled={
                                `${approver?.userId}` !== currentUser?.id
                              }
                              onSelect={(value) =>
                                onChangeStatus(approver, value)
                              }
                              options={[
                                {
                                  value: "approved",
                                  text: "✅",
                                  tooltipContent: "Approve",
                                },
                                {
                                  value: "changes-requested",
                                  text: "🔁",
                                  tooltipContent: "Request changes",
                                },
                                {
                                  value: "rejected",
                                  text: "❌",
                                  tooltipContent: "Reject",
                                },
                              ]}
                            />
                          </Flex>
                        </Flex>
                        {commentsByUserId[approver?.userId]?.map((comment) => (
                          <Box
                            key={comment.updateId}
                            backgroundColor={
                              Box.backgroundColors.PRIMARY_BACKGROUND_COLOR
                            }
                            padding={Box.paddings.SMALL}
                            rounded={Box.roundeds.MEDIUM}
                          >
                            <Flex gap={8}>
                              <Icon
                                style={{ flex: "0 0 auto" }}
                                icon={Update}
                                iconSize={20}
                              />
                              <Text>{comment.text}</Text>
                              <Text
                                style={{
                                  marginLeft: "auto",
                                  flex: "0 0 auto",
                                }}
                              >
                                {statusTexts[comment.status]} -{" "}
                                {comment.date &&
                                  formatRelative(
                                    parseISO(comment.date),
                                    new Date()
                                  )}
                              </Text>
                            </Flex>
                          </Box>
                        ))}
                        {hasComments &&
                          approver?.status &&
                          // Converting to string because the API has changed to always using strings for IDs. Storage has ints stored for old values
                          `${approver?.userId}` === currentUser?.id && (
                            <Flex
                              direction={Flex.directions.COLUMN}
                              alignItems={Flex.align.STRETCH}
                              gap={4}
                            >
                              <TextareaAutosize
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                minRows={2}
                                className="textarea"
                                placeholder="Leave a comment about your decision (optional)"
                              ></TextareaAutosize>
                              <Button
                                style={{ marginLeft: "auto" }}
                                kind={Button.kinds.SECONDARY}
                                leftIcon={Update}
                                size={Button.sizes.SMALL}
                                onClick={() => {
                                  addItemUpdate({
                                    text: comment,
                                    status: approver?.status,
                                  });
                                }}
                              >
                                Leave comment
                              </Button>
                            </Flex>
                          )}
                      </Flex>
                    </Box>
                  </motion.div>
                )
              )}
          </AnimatePresence>
          {loading && (
            <>
              <Skeleton width={"100%"} height={40}></Skeleton>
              <Skeleton width={"100%"} height={40}></Skeleton>
              <Skeleton width={"100%"} height={40}></Skeleton>
            </>
          )}
        </Flex>
      </Box>
    </Box>
  );
};

function ViewerOnly() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <img
        style={{
          overflow: "hidden",
          borderRadius: "50%",
          hight: "150px",
          width: "150px",
        }}
        src={logo}
        alt="Approvals logo"
      />
      <h1>Approval Workflows</h1>

      <p style={{ marginBottom: "20px", maxWidth: "600px" }}>
        As a viewer, you are unable to use the Approval Workflows app. Please
        contact your admin to change your permissions.
      </p>
    </div>
  );
}

function welcomeMessage() {
  const openSettings = () => {
    amplitude.track("Settings opened");
    monday.execute("openSettings").then((res) => {
      // refresh the page
      window.location.reload();
    });
  };

  return (
    <Box
      border={Box.borders.DEFAULT}
      rounded={Box.roundeds.MEDIUM}
      marginTop={Box.marginTops.MEDIUM}
      padding={Box.paddings.MEDIUM}
    >
      <Flex gap={Flex.gaps.MEDIUM}>
        <img
          style={{
            flex: "0 0 auto",
            overflow: "hidden",
            borderRadius: "50%",
            hight: "50px",
            width: "50px",
          }}
          src={logo}
          alt="Approvals logo"
        />
        <div
          style={{
            flex: `1 1 auto`,
          }}
        >
          <Text weight={Text.weights.BOLD}>Welcome to Approval Workflows</Text>
          <Text element="p">
            A small amount of configuration is recommended to make the most of
            Approval Workflows. Open settings and select or create a column for
            your approval status.
          </Text>
        </div>
        <Button
          style={{ flex: "0 0 auto" }}
          size="large"
          onClick={openSettings}
        >
          Open settings
        </Button>
      </Flex>
    </Box>
  );
}

export default ItemView;
