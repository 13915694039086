import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import ItemView from "./routes/ItemView";
import TemplateModal from "./routes/TemplateModal";
import * as serviceWorker from "./serviceWorker";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import * as Sentry from "@sentry/react";
import { monday } from "./helpers/mondaySdk";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://7d952a65f7ec8d1a3ca1a624476856af@o1245793.ingest.sentry.io/4505997027835904",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

function ThemeController({ children }) {
  const [storedTheme, setTheme] = React.useState("light");

  useEffect(() => {
    monday.listen("context", (res) => {
      const theme = res.data.theme;
      if (storedTheme) {
        document.body.classList.remove(`${storedTheme}-app-theme`);
      }
      if (theme) {
        document.body.classList.add(`${theme}-app-theme`);
        setTheme(theme);
      }
    });

    monday.get("context").then((res) => {
      if (process.env.NODE_ENV !== "development" && res?.data?.user?.id) {
        amplitude.init("87891b06c39d542f02d738b88ff69211", res.data.user.id);
      }

      if (!res.data?.user?.isViewOnly) {
        monday
          .api(
            `query {
              me {
                account {
                  name
                  id
                }
              }
            }`
          )
          .then((query) => {
            if (process.env.NODE_ENV !== "development") {
              const identify = new amplitude.Identify()
                .set("account_id", query?.data?.me?.account?.id)
                .set("account_name", query?.data?.me?.account?.name);
              amplitude.identify(identify);
            }
          });
      }
    });
  }, [storedTheme]);

  return <>{children}</>;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <ItemView />,
  },
  {
    path: "/template",
    element: <TemplateModal />,
  },
]);

const root = createRoot(document.getElementById("root"));
root.render(
  <ThemeController>
    <RouterProvider router={router}></RouterProvider>
  </ThemeController>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
