import React, { useEffect, useMemo, useState } from "react";
import { monday } from "../helpers/mondaySdk";

import { v4 as uuid } from "uuid";
import "./TemplateModal.css";

import {
  Text,
  Accordion,
  AccordionItem,
  Button,
  Chips,
  Dropdown,
  ButtonGroup,
  Flex,
  Box,
  List,
  ListItem,
  TextField,
  Checkbox,
} from "monday-ui-react-core";
import { useSearchParams } from "react-router-dom";
import { getItemStorageKey } from "./ItemView";
import { Heading } from "monday-ui-react-core/next";
import { Add } from "monday-ui-react-core/icons";
import * as amplitude from "@amplitude/analytics-browser";

export const getBoardSettingsKey = (boardId) => `board-${boardId}-settings`;

export const getBoardSettings = async (boardId) => {
  const key = getBoardSettingsKey(boardId);

  const defaultTemplateId = JSON.parse(
    (await monday.storage.getItem(key)).data.value || "{}"
  );
  return defaultTemplateId;
};

export const updateBoardSettings = async (boardId, settings) => {
  const key = getBoardSettingsKey(boardId);

  const existingSettings = await getBoardSettings(boardId);

  monday.storage.setItem(
    key,
    JSON.stringify({
      ...existingSettings,
      ...settings,
    })
  );
};

export const getGlobalSettings = async () => {
  const key = "global-settings";

  const defaultTemplateId = JSON.parse(
    (await monday.storage.getItem(key)).data.value || "{}"
  );

  return defaultTemplateId;
};

export const updateGlobalSettings = async (settings) => {
  const key = "global-settings";

  const existingSettings = await getGlobalSettings();

  monday.storage.setItem(
    key,
    JSON.stringify({
      ...existingSettings,
      ...settings,
    })
  );
};

const TemplateList = ({ templates, onEditTemplate, onDeleteTemplate }) => {
  const [expandedTemplateIndex, setExpandedTemplateIndex] = useState(null);
  const [defaultTemplateId, setDefaultTemplateId] = useState(null);
  const [newBoardDefaultTemplateId, setNewBoardDefaultTemplateId] =
    useState(null);
  const [defaultTemplateForGroup, setDefaultTemplateForGroup] = useState({});
  const [boardGroups, setBoardGroups] = useState([]);
  const [boardName, setBoardName] = useState("");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    amplitude.track("Viewed templates");
  }, []);

  useEffect(() => {
    const boardId = searchParams.get("parentBoardId");

    getBoardSettings(boardId).then((settings) => {
      setDefaultTemplateId(settings.defaultTemplateId);
      setDefaultTemplateForGroup(settings.defaultTemplateForGroup || {});
    });

    getGlobalSettings().then((settings) => {
      setNewBoardDefaultTemplateId(settings.newBoardDefaultTemplateId);
    });

    //get board groups
    monday
      .api(
        `query {
        boards(ids: ${boardId}) {
          name
          groups {
            id
            title
          }
        }
      }`
      )
      .then((res) => {
        setBoardName(res.data.boards[0].name);
        setBoardGroups(res.data.boards[0].groups);
      });
  }, [searchParams]);

  const templatesArray = useMemo(
    () =>
      Object.entries(templates).map(([id, template]) => ({
        id,
        ...template,
      })),
    [templates]
  );

  const options = useMemo(
    () => [
      {
        id: 1,
        // TODO show name of item here?
        label: "Apply to item",
      },
      {
        id: 3,
        label: "Edit template",
      },
      {
        id: 4,
        label: "Delete template",
      },
    ],
    []
  );

  const setAsDefaultTemplate = async () => {
    const boardId = searchParams.get("parentBoardId");

    const newDefaultTemplateId = templatesArray[expandedTemplateIndex].id;

    const newSettings = {
      defaultTemplateId: newDefaultTemplateId,
    };

    updateBoardSettings(boardId, newSettings);

    setDefaultTemplateId(newDefaultTemplateId);

    amplitude.track("Default template set");
  };

  const removeAsDefaultTemplate = async () => {
    const boardId = searchParams.get("parentBoardId");

    const newSettings = {
      defaultTemplateId: null,
    };

    updateBoardSettings(boardId, newSettings);

    setDefaultTemplateId(null);

    amplitude.track("Default template removed");
  };

  const setAsNewBoardDefaultTemplate = async () => {
    const newDefaultTemplateId = templatesArray[expandedTemplateIndex].id;

    const newSettings = {
      newBoardDefaultTemplateId: newDefaultTemplateId,
    };

    updateGlobalSettings(newSettings);

    setNewBoardDefaultTemplateId(newDefaultTemplateId);

    amplitude.track("New Board Default template set");
  };

  const removeAsNewBoardDefaultTemplate = async () => {
    const newSettings = {
      newBoardDefaultTemplateId: null,
    };

    updateGlobalSettings(newSettings);

    setNewBoardDefaultTemplateId(null);

    amplitude.track("New Board Default template removed");
  };

  function getSelectedOptionsForTemplate(templateId) {
    const groupsWithDefaultTemplate = Object.entries(defaultTemplateForGroup)
      .filter(([, storedTemplate]) => storedTemplate === templateId)
      .map(([groupId]) => groupId);

    return boardGroupOptions.filter((option) =>
      groupsWithDefaultTemplate.includes(option.value)
    );
  }

  const setAsDefaultTemplateForGroups = async (groups = []) => {
    const boardId = searchParams.get("parentBoardId");

    const newDefaultTemplateId = templatesArray[expandedTemplateIndex].id;

    const existingSettings = await getBoardSettings(boardId);

    const existingGroupsForTemplate =
      getSelectedOptionsForTemplate(newDefaultTemplateId);

    const groupsToRemove = existingGroupsForTemplate.filter((group) =>
      groups.every((g) => g.value !== group.value)
    );

    const newSettings = {
      defaultTemplateForGroup: {
        ...existingSettings.defaultTemplateForGroup,
        ...groups.reduce(
          (acc, group) => ({
            ...acc,
            [group.value]: newDefaultTemplateId,
          }),
          {}
        ),
      },
    };

    groupsToRemove.forEach((group) => {
      delete newSettings.defaultTemplateForGroup[group.value];
    });

    updateBoardSettings(boardId, newSettings);

    setDefaultTemplateForGroup(newSettings.defaultTemplateForGroup);
  };

  const onOptionClick = async (value) => {
    if (value.id === 1) {
      await monday.storage.setItem(
        getItemStorageKey(searchParams.get("itemId")),
        JSON.stringify(templatesArray[expandedTemplateIndex].approvers)
      );

      monday.execute("closeAppFeatureModal");

      amplitude.track("Applied template to item");
    }

    if (value.id === 3) {
      onEditTemplate(templatesArray[expandedTemplateIndex].id);

      amplitude.track("Edit template");
    }

    if (value.id === 4) {
      onDeleteTemplate(templatesArray[expandedTemplateIndex].id);

      amplitude.track("Delete template");
    }
  };

  const boardGroupOptions = boardGroups.map((group) => ({
    value: group.id,
    label: group.title,
  }));

  if (!templatesArray.length) {
    return null;
  }

  return (
    <Accordion
      className="monday-storybook-accordion_small-wrapepr"
      defaultIndex={[expandedTemplateIndex]}
    >
      {templatesArray.map((template, index) => (
        <AccordionItem
          key={template.id}
          title={
            <Flex direction={Flex.directions.COLUMN} align={Flex.align.START}>
              <Box marginBottom={Box.marginBottoms.SMALL}>{template.name}</Box>
              <Flex align={Flex.align.START}>
                {defaultTemplateId === template.id && (
                  <Chips label={`Default for board: ${boardName}`} readOnly />
                )}
                {newBoardDefaultTemplateId === template.id && (
                  <Chips
                    label={`New board default`}
                    color={Chips.colors.DARK_BLUE}
                    readOnly
                  />
                )}
                {Object.entries(defaultTemplateForGroup).some(
                  ([, storedTemplate]) => storedTemplate === template.id
                ) && (
                  <Chips
                    label={`Default for groups`}
                    color={Chips.colors.NAVY}
                    readOnly
                  />
                )}
              </Flex>
            </Flex>
          }
          onClick={() => setExpandedTemplateIndex(index)}
        >
          <Box paddingX={Box.paddingXs.SMALL}>
            <List>
              {options.map((option) => (
                <ListItem key={option.id} onClick={() => onOptionClick(option)}>
                  <Flex
                    direction={Flex.directions.COLUMN}
                    align={Flex.align.START}
                  >
                    <Text color={Text.colors.PRIMARY}>{option.label}</Text>
                  </Flex>
                </ListItem>
              ))}
              <Box
                backgroundColor={
                  Box.backgroundColors.SECONDARY_BACKGROUND_COLOR
                }
                padding={Box.paddings.MEDIUM}
                rounded={Box.roundeds.MEDIUM}
              >
                <Flex
                  gap={Flex.gaps.MEDIUM}
                  direction={Flex.directions.COLUMN}
                  align={Flex.align.STRETCH}
                >
                  <Checkbox
                    label={`Use as the default for board: ${boardName}`}
                    checked={defaultTemplateId === template.id}
                    onChange={() => {
                      if (defaultTemplateId === template.id) {
                        removeAsDefaultTemplate();
                      } else {
                        setAsDefaultTemplate();
                      }
                    }}
                  />
                  <Checkbox
                    label="Use as the default for new boards"
                    checked={newBoardDefaultTemplateId === template.id}
                    onChange={() => {
                      if (newBoardDefaultTemplateId === template.id) {
                        removeAsNewBoardDefaultTemplate();
                      } else {
                        setAsNewBoardDefaultTemplate();
                      }
                    }}
                  />
                  <Box>
                    <Box marginBottom={Box.marginBottoms.SMALL}>
                      <Heading
                        type={Heading.types.H3}
                        weight={Heading.weights.NORMAL}
                      >
                        Use as default for groups
                      </Heading>
                      <Text color={Text.colors.SECONDARY}>
                        Group defaults will take precedence over the board
                        default
                      </Text>
                    </Box>
                    <Dropdown
                      placeholder="Default for groups"
                      defaultValue={getSelectedOptionsForTemplate(template.id)}
                      options={boardGroupOptions}
                      onChange={(groups) => {
                        setAsDefaultTemplateForGroups(groups);
                      }}
                      multi
                      multiline
                      insideOverflowContainer
                    />
                  </Box>
                </Flex>
              </Box>
            </List>
          </Box>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

const NewTemplateForm = ({ onSubmit, template, templateId }) => {
  const [name, setName] = useState(template?.name ?? "");
  const [approvers, setApprovers] = useState(template?.approvers ?? []);

  const handleSubmit = () => {
    onSubmit({ name, approvers, templateId });
  };

  const [users, setUsers] = useState([]);
  const [, setCurrentUser] = useState();
  const [newUserDropdownKey, setNewUserDropdownKey] = useState(0);

  useEffect(() => {
    monday
      .api(
        `query {
        users {
          id
          name
          photo_thumb_small
        }
      }`
      )
      .then((res) => {
        setUsers(res.data.users);
      });

    monday
      .api(
        `query {
        me {
          id
          name
          photo_thumb_small
        }
      }`
      )
      .then((res) => {
        if (res?.data?.me) {
          setCurrentUser(res.data.me);
        }
      });
  }, []);

  const optionsAvatar = users.map((user) => ({
    value: user.id,
    label: user.name,
    leftAvatar: user.photo_thumb_small,
  }));

  const optionsWithoutApprovers = optionsAvatar.filter(
    // Converting to string because the API has changed to always using strings for IDs. Storage has ints stored for old values
    (option) =>
      !approvers.some((approver) => `${approver?.userId}` === option.value)
  );

  const onSelectApprover = (event, index) => {
    const approver = {
      userId: event.value,
    };
    setApprovers([...approvers.slice(0, index), approver]);

    const previousUserId = approvers[index]?.userId;

    if (!previousUserId) {
      setNewUserDropdownKey(newUserDropdownKey + 1);
    }

    if (previousUserId) {
      amplitude.track("Changed template approver");
    } else {
      amplitude.track("Added template approver");
    }
  };

  const onRemoveApprover = (approver) => {
    setApprovers(approvers.filter((a) => a.userId !== approver?.userId));

    amplitude.track("Removed template approver");
  };

  if (!users.length) {
    // TODO better loading state
    return <div>Loading...</div>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box marginBottom={Box.marginBottoms.SMALL}>
        <Text type={Text.types.TEXT1} weight={Text.weights.MEDIUM}>
          Name
        </Text>
      </Box>
      <Box marginBottom={Box.marginBottoms.MEDIUM}>
        <TextField
          required
          value={name}
          size={TextField.sizes.MEDIUM}
          onChange={(value) => setName(value)}
          placeholder="Name"
        />
      </Box>
      <Box marginBottom={Box.marginBottoms.SMALL}>
        <Text type={Text.types.TEXT1} weight={Text.weights.MEDIUM}>
          Approvers
        </Text>
      </Box>
      <Flex
        gap={6}
        direction={Flex.directions.COLUMN}
        align={Flex.align.STRETCH}
      >
        {[...approvers, null].map((approver, index) => (
          <Flex
            key={approver?.userId ?? newUserDropdownKey}
            gap={8}
            align={Flex.align.STRETCH}
          >
            <div style={{ flex: "1 1 auto" }}>
              <Dropdown
                insideOverflowContainer
                placeholder="Add new approver"
                options={optionsWithoutApprovers}
                value={optionsAvatar.find(
                  // Converting to string because the API has changed to always using strings for IDs. Storage has ints stored for old values
                  (option) => option.value === `${approver?.userId}`
                )}
                onChange={(event) =>
                  event
                    ? onSelectApprover(event, index)
                    : onRemoveApprover({ userId: approver.userId })
                }
              ></Dropdown>
            </div>
            <div style={{ flex: "0 0 auto" }}>
              <ButtonGroup
                groupAriaLabel="button group aria label"
                value={undefined}
                size={ButtonGroup.sizes.MEDIUM}
                disabled={true}
                options={[
                  {
                    value: "approved",
                    text: "✅",
                    tooltipContent: "Approve",
                  },
                  {
                    value: "changes-requested",
                    text: "🔁",
                    tooltipContent: "Request changes",
                  },
                  {
                    value: "rejected",
                    text: "❌",
                    tooltipContent: "Reject",
                  },
                ]}
              />
            </div>
          </Flex>
        ))}
      </Flex>
      <Flex justify={Flex.justify.END}>
        <Box marginTop={Box.marginTops.MEDIUM}>
          <Button type={Button.types.SUBMIT}>Save template</Button>
        </Box>
      </Flex>
    </form>
  );
};

export const getExistingTemplates = async () => {
  const existingTemplates = JSON.parse(
    (await monday.storage.getItem("templates")).data.value || "{}"
  );

  return existingTemplates;
};

export default function TemplateModal() {
  const [location, setLocation] = useState("LIST");
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [templates, setTemplates] = useState({});

  useEffect(() => {
    getExistingTemplates().then((existingTemplates) => {
      setTemplates(existingTemplates);
    });
  }, []);

  const saveNewTemplate = async (template) => {
    const existingTemplates = await getExistingTemplates();

    const newTemplates = {
      ...existingTemplates,
      [uuid()]: template,
    };

    monday.storage.setItem("templates", JSON.stringify(newTemplates));

    setTemplates(newTemplates);

    amplitude.track("Created new template");
  };

  return (
    <Box padding={Box.paddings.MEDIUM}>
      <Box padding={Box.paddings.MEDIUM}>
        {location === "LIST" && <Heading>Approval templates</Heading>}
        {location === "NEW" && !selectedTemplateId && (
          <Heading>New template</Heading>
        )}
        {location === "NEW" && selectedTemplateId && (
          <Heading>Edit template</Heading>
        )}
        {location === "LIST" && (
          <Box marginTop={Box.marginTops.SMALL}>
            <Text element="p">
              Create templates to reuse the same approvers across items. Default
              templates can be set for boards and groups.
            </Text>
          </Box>
        )}
      </Box>
      <Box paddingX={Box.paddingXs.MEDIUM} paddingY={Box.paddingYs.SMALL}>
        {location === "LIST" && (
          <>
            <TemplateList
              templates={templates}
              onEditTemplate={(templateId) => {
                setSelectedTemplateId(templateId);
                setLocation("NEW");
              }}
              onDeleteTemplate={(templateId) => {
                const newTemplates = { ...templates };
                delete newTemplates[templateId];

                monday.storage.setItem(
                  "templates",
                  JSON.stringify(newTemplates)
                );

                setTemplates(newTemplates);
              }}
            />
            <Flex justify={Flex.justify.END}>
              <Box marginTop={Box.marginTops.SMALL}>
                <Button
                  leftIcon={Add}
                  onClick={() => setLocation("NEW")}
                  kind={Button.kinds.SECONDARY}
                >
                  Create template
                </Button>
              </Box>
            </Flex>
          </>
        )}
        {location === "NEW" && (
          <NewTemplateForm
            onSubmit={(template) => {
              if (template.templateId) {
                const newTemplates = {
                  ...templates,
                  [template.templateId]: template,
                };

                monday.storage.setItem(
                  "templates",
                  JSON.stringify(newTemplates)
                );

                setTemplates(newTemplates);

                amplitude.track("Updated template");
              } else {
                saveNewTemplate(template);
              }

              setLocation("LIST");
              setSelectedTemplateId(null);
            }}
            template={templates[selectedTemplateId]}
            templateId={selectedTemplateId}
          />
        )}
      </Box>
    </Box>
  );
}
